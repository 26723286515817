<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 用户管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <!--<el-button type="danger" icon="delete" class="handle-del mr10" @click="delAll">批量删除</el-button>-->
                <!--<el-select v-model="select_cate" placeholder="筛选省份" class="handle-select mr10">-->
                    <!--<el-option key="1" label="广东省" value="广东省"></el-option>-->
                    <!--<el-option key="2" label="湖南省" value="湖南省"></el-option>-->
                <!--</el-select>-->
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
				<el-date-picker
				        class="mr10"
				        v-model="value2"
				        type="datetimerange"
				        format="yyyy-MM-dd HH:mm:ss"
				        :picker-options="pickerOptions"
				        @change="changeTime"
				        range-separator="至"
				        start-placeholder="开始日期"
				        end-placeholder="结束日期"
				        align="right">
				</el-date-picker>
                <el-select v-if="isShowSelect" v-model="selectedCid" class="mr10" @change="change">
                    <el-option
                            v-for="item in clist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">{{item.name}}
                    </el-option>
                </el-select>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
                <export-excel :apiUrl="apiUrl" :params="params" :filename="filename" :tHeader="tHeader" :tValue="tValue"></export-excel>
                <el-breadcrumb separator="/" style="float: right">
                    <el-breadcrumb-item>总人数：{{count}}</el-breadcrumb-item>
                </el-breadcrumb>
                <!--<el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>-->
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="nickname" align="center" label="微信昵称">
                </el-table-column>
                <el-table-column prop="sex" align="center" label="性别">
                </el-table-column>
                <el-table-column prop="country" align="center" label="国家">
                </el-table-column>
                <el-table-column prop="city" align="center" label="城市">
                </el-table-column>
                <el-table-column prop="tel" align="center" label="手机号码">
                </el-table-column>
                <el-table-column prop="integral" align="center" label="用户积分">
                </el-table-column>
                <el-table-column prop="avatarurl"  align="center" label="头像">
                    <template   slot-scope="scope">
                        <el-popover
                                placement="left"
                                title=""
                                width="500"
                                trigger="hover">
                            <img :src="scope.row.avatarurl" style="max-width: 100%" />
                            <img slot="reference" :src="scope.row.avatarurl" :alt="scope.row.avatarurl" style="max-width: 130px; height: auto; max-height: 100px">
                        </el-popover>
                        <!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
                    </template>
                </el-table-column>
                <el-table-column prop="cname" align="center" label="所属咨询">
                </el-table-column>
                <!--<el-table-column prop="staffname" align="center" label="员工姓名">-->
                <!--</el-table-column>-->
                <el-table-column prop="datetime"  align="center" label="更新时间" sortable width="155">
                </el-table-column>
                <!--<el-table-column label="操作" width="150" align="center">-->
                    <!--<template slot-scope="scope">-->
                        <!--&lt;!&ndash;<el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">关联咨询</el-button>&ndash;&gt;-->
                        <!--<el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">设置为员工</el-button>-->
                        <!--&lt;!&ndash;<el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>&ndash;&gt;-->
                    <!--</template>-->
                <!--</el-table-column>-->
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <!--<el-dialog title="关联咨询" v-loading="loading" :visible.sync="editVisible" width="30%">-->
            <!--<el-form ref="form" :model="form" label-width="80px">-->
                <!--<el-form-item label="关联咨询">-->
                    <!--<el-select filterable v-model="form.cid" placeholder="请选择咨询姓名">-->
                        <!--<el-option-->
                                <!--v-for="item in cList"-->
                                <!--:key="item.id"-->
                                <!--:label="item.name"-->
                                <!--:value="item.id">{{item.name}}  <span style="color:red">{{item.tips}}</span>-->
                        <!--</el-option>-->
                    <!--</el-select>-->
                <!--</el-form-item>-->

            <!--</el-form>-->
            <!--<span slot="footer" class="dialog-footer">-->
                <!--<el-button @click="hideEditVisible">取 消</el-button>-->
                <!--<el-button type="primary" @click="saveEdit('form')">确 定</el-button>-->
            <!--</span>-->
        <!--</el-dialog>-->

        <el-dialog title="员工信息" v-loading="loading" :visible.sync="editVisible" width="20%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="员工姓名" prop="staffname">
                    <el-input v-model="form.staffname"  placeholder="请输入员工姓名"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideEditVisible">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import  ExportExcel from '.././utils/index';
    export default {
        name: 'basetable',
        components: { ExportExcel},
        data() {
            return {
				pickerOptions: {
				    shortcuts: [{
				        text: '最近一周',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近一个月',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近三个月',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近半年',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近一年',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
				            picker.$emit('pick', [start, end]);
				        }
				    }]
				},
				value2: [],
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id:'',
                    username: '',
                    nickName:'',
                    tel:'',
                    avatarurl:'',
                    num:'',
                    sub:[],
                    datetime:'',
                    cid: null,
                    staffname: '',
                    // type:[],
                },
                cList: [],  //咨询列表
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                subForm:[],  //裂变用户列表
                loading:false,
                count:0,
                tHeader:['ID', '微信昵称', '性别', '国家', '省份', '城市', '手机号码', '所属咨询', '更新时间'],
                tValue:['id', 'nickname', 'sex', 'country', 'province', 'city','tel', 'cname', 'datetime'],
                filename:'微商城所有用户数据',
                apiUrl: "ShopUser/exportExcel",
                params: null,
                clist: [  //咨询列表
                    { id: 0, name: "所有咨询"}
                ],
                selectedCid: 0,  //当前选中的咨询列表
                isShowSelect: false,  //是否展示选择姓名下拉框
                rules: {
                    staffname: [
                        { required: true, message: '请输入员工姓名', trigger: 'blur' }
                    ],
                }
            }
        },
        created() {
            //获取初始数据
			this.params={
			    select_word: this.select_word,
			    status: this.selectedStatus,
			    cid: this.selectedCid,
			    startTime: this.value2 ? this.value2[0] : null,
			    endTime: this.value2 ? this.value2[1] : null,
			};
            this.getData();
            this.getCardList();  //获取所有咨询列表
            const userInfo=JSON.parse(localStorage.getItem('userInfo'));
            if(userInfo.cid){
                this.isShowSelect=false;
            }else{
                this.isShowSelect=true;
            }
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.username === this.del_list[i].username) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            //获取咨询列表
            getCardList(){
                this.$api.post('ShopOrder/getCardList', null, res => {
                    this.clist=this.clist.concat(res.data);
                }, err => {

                });
            },
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                // console.log(file);
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            change(){
                // console.log(this.selectedStatus);
                this.getData();
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //图片上传成功
            handleAvatarSuccess(res, file){
                this.loading=false;
                // console.log(res);
                this.form.picid=res.data;
                this.form.b_image = URL.createObjectURL(file.raw);
                this.getData();
                this.$message.success(res.msg);
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
				this.params={
				    select_word: this.select_word,
				    status: this.selectedStatus,
				    cid: this.selectedCid,
				    startTime: this.value2 ? this.value2[0] : null,
				    endTime: this.value2 ? this.value2[1] : null,
				};
				
				
				
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number,
                    cid: this.selectedCid,
					startTime: this.value2 ? this.value2[0] : null,
					endTime: this.value2 ? this.value2[1] : null,
                });
                // console.log(params);
                this.$api.post('ShopUser/getUserList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    this.count = res.data.count;
                    console.log(res);
                }, err => {
                    this.tableData = [];
                    this.sumPage = 1*10;
                    this.cur_page = 1;
                    this.count = 0;
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            formatter(row, column) {
                return row.username;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index, row, status) {
                this.getConsultCationList();  //获取咨询列表
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id:null,
                        username: null,
                        nickName:null,
                        password:null,
                        role:null,
                        b_image: null,
                        datetime: null,
                        picid:null,
                        roleid:null,
                        menuList:null,
                        menuName:null,
                        wechatid:null,
                        staffname: null,
                        // type:[],
                    };
                    this.type=[];
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    this.form = {
                        id: item.id,
                        // cid: item.cid,
                        staffname: item.staffname,
                    };

                }
                this.editVisible = true;
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                picid: this.form.picid,
                                username: this.form.username,
                                weChatId: this.form.wechatid,
                                password: this.form.password,
                                roleid: this.form.roleid,
                                menuId: this.type
                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                // cid: this.form.cid
                                staffname: this.form.staffname
                            });
                        }
                        // console.log(params);
                        // console.log({
                        //     id: this.form.id,
                        //     picid: this.form.picid,
                        //     username: this.form.username,
                        //     weChatId: this.form.wechatid,
                        //     password: this.form.password,
                        //     roleid: this.form.roleid,
                        //     menuId: this.type
                        // });
                        // return;
                        this.$api.post('ShopUser/setStaffName', params, res => {
                            this.getData();
                            this.$message.success(res.msg);
                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据")
                        return false;
                    }
                });
            },
            // 确定删除
            deleteRow(){
                // console.log(this.form);
                // return;
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                console.log(this.form);
                this.$api.post('AdminUser/deleteAdminUser', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            hideEditVisible(){
                this.editVisible=false;
            },
			//确认时间时发起搜索
			changeTime(){
			    this.getData();
			},
            //获取咨询列表
            getConsultCationList(){
                this.$api.post('ShopUser/getConsultCationList', null, res => {
                    this.cList=res.data;
                    // console.log(this.cList);
                }, err => {
                    this.$message.error(err.msg);
                });
            },
        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
</style>
